/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Ghalia Adzana
==============================================================================================*/
@import "sass-lib";
body {
    color: #333;
    font-size: 14px;
    font-family: "open sans";
    position: relative;
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; }
h5 { font-size: 16px; line-height:1.4}
h6 { font-size: 14px; }

/* structure
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}
.trigger{ position: absolute; left: 0; top: 0; right: 0; bottom: 0;  height: 2px; display: block; margin: auto;  }
section { position: relative; }
.wrapper {
    width: 1570px;margin: 0 auto; padding: 0 100px; @include boxSizing(border-box); position: relative;
    &.general{
        position: absolute; left: 0; right: 0; overflow: hidden; margin: 0 auto;
    }
}
.btn-seedetail{
    font-size: 14px; font-weight: bold; display: inline-block; position: relative; color: #66B82E;
    text-transform: uppercase; padding-right: 50px; @include transition(.4s all ease-out);
    &:after{
        content:''; position: absolute; right: 10px; top: 0; bottom: 0; margin: auto 0; background: url(../images/material/ic-right-green.png)no-repeat center;
        width: 29px; height: 16px; @include transition(.4s all ease-out);
     }
     &:hover{
        color: #193D6F;
        &:after{
            right:0;
        }
     }
}

/* std-content
----------------------------------------------------------------------------------------------*/
.std-content{
    ul {
        margin: 0 0 30px 0; counter-reset: list; list-style: none;
        > li {
            font-size: 16px; color: #646464; line-height: 32px; padding-left:30px; position: relative;
            margin: 5px 0; list-style: none; counter-increment: none;
            &:before{
                content:counter(list,lower-alpha); counter-increment: list;
                position: absolute;left: 0; top: 0; font-size: 16px; line-height: 32px; color:#009BE0; font-weight: bold;
            }

        }
        &.bullet{
            counter-reset: none;
            li{
                &:before{
                    content:''; position: absolute; left: 0; top: 10px; width: 18px; height: 18px;
                    background: url(../images/material/bullet-li.png)no-repeat center;  counter-increment: none;
                }
            }
        }
    }
    ol {
        margin: 0 0 30px 0; list-style: none;
        counter-reset:  my-awesome-counter;
        > li {
            font-size: 16px; color: #646464; line-height: 32px; padding-left: 30px;
             position: relative; counter-increment:  my-awesome-counter; margin:5px 0;
            &:before{
                content:counter( my-awesome-counter)"."; position: absolute; left: 0; top: 0;font-size: 16px; line-height: 32px;
                color: #009BE0; font-weight: bold;
            }
        }
    }
    table,thead,tbody,tfoot,tr,th,td{
        border: 1px solid grey;
    }
    table{
        th,td{padding: 10px;line-height: 1.3;}
        th{font-weight: bold;}
    }
    b{ font-size:18px; color: #111; margin-bottom: 20px; display: block; }
    p{ font-size:16px; color:#464646;line-height: 32px;    }
}


/* header
----------------------------------------------------------------------------------------------*/
header{
    position: fixed; top: 0; left: 0; right: 0; background: rgba(0,155,224,0.7); height: 94px;
    z-index: 99;  @include transition(.2s all ease-out);
    @include afterclear;
    .search-mobile{ display: none; }
    .toggle-mobile{ display: none; }
    .wrapper{ width: 1620px; padding: 0; margin: 0; }
    .logo{ position: absolute; left: 0; top: 0; }
    .right-header{
        float: right; padding-top:40px;
        @include afterclear;
        nav{
            float: left;
           > ul{
                >li{
                    display: inline-block; float: left; margin: 0 20px; position: relative;
                    padding-bottom: 40px;
                    >a{
                        text-transform: uppercase; color: #fff;
                        -webkit-transition: color 0.3s;
                        -moz-transition: color 0.3s;
                        transition: color 0.3s;
                        position: relative;
                        &:before{
                            position: absolute;top: 0%; left: 50%; color: transparent; content: '.';
                            text-shadow: 0 0 transparent; font-size: 40px;
                            -webkit-transition: text-shadow 0.3s, color 0.3s;
                            -moz-transition: text-shadow 0.3s, color 0.3s;
                            transition: text-shadow 0.3s, color 0.3s;
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            transform: translateX(-50%);
                            pointer-events: none;
                        }
                        &:hover{
                            &:before{
                                color: #fff;  text-shadow: 10px 0 #fff, -10px 0 #fff;
                            }
                        }
                    }
                    &:hover{
                        ul{
                            opacity: 1; visibility: visible;
                        }
                    }
                }
                ul{
                    position: absolute; top: 100%; left: 50%; right: 0; width: 200px; background: #fff;
                    z-index: 4; display: block; border-top: 4px solid #2864A0;  margin-top: -4px;
                    @include transform(translateX(-50%)); @include borderRadius(5px); @include boxShadow(0 0 20px -15px #000);
                    opacity: 0; visibility: hidden; @include transition(.2s all ease-out);

                    &:before{
                        content:''; position: absolute; top: -10px; left: 0; right: 0;
                        border-bottom: 10px solid #2864A0; border-left: 12px solid transparent; border-right:12px solid transparent;
                        display: block; width: 0; height: 0; margin: auto;
                    }
                   >li{
                        float: none; display: block; text-align: center; border-bottom: 1px solid #eee;
                        padding: 15px 5px; @include boxSizing(border-box); border-left: 3px solid transparent;
                        @include transition(.2s all ease-out);
                        &:last-child{ border-bottom:none; }
                        a{
                            font-size: 14px; text-transform: uppercase; font-weight: 600; color: #111; line-height: 22px;
                            @include transition(.2s all ease-out);
                        }
                        &:hover,&.active{
                            border-left: 3px solid #193D6F;
                             a{color:#29308e; }
                        }
                    }
                }
            }

        }
        .box-search{
            float: left; margin-top: -5px; margin-left:20px; position: relative;
            .search-drop{
                position: absolute; top: 80px; right: 0; left: -345px;  width: 380px;
               height:70px; display: none; @include boxShadow(0 0 25px -15px #000);
               &:before{
                    content: ''; position: absolute;top: -10px;right: 20px;border-bottom: 10px solid #fff;
                    border-left: 5px solid transparent;border-right: 5px solid transparent; display: block;
                    width: 0; height: 0; margin: auto;
               }
               input[type='text']{
                    width: 100%; @include boxSizing(border-box); height: 100%; border:none;
                    font-size: 16px; color: #646464;  @include borderRadius(10px);
                }
            }
            @include placeholder{ font-style: italic; }
            .black{ display: none; }
        }
    }
    &.float{
        background: #fff; @include boxShadow(0 0 20px -15px #000);
        .right-header{
            nav{
                ul{
                    li{
                        a{
                         color: #111;
                             &:hover{
                                color:#193D6F;
                                &:before{
                                    color: #111;  text-shadow: 10px 0 #111, -10px 0 #111;
                                }
                            }
                        }
                    }
                }
            }
        }
        .box-search{
            .black{ display: block; }
            .white{  display: none;}
        }
    }
    .top-mobile{ display: none; }
}
/* middle
----------------------------------------------------------------------------------------------*/

.banner-home{
    position: relative; overflow: hidden;
    figure{
        img{ width: 100%; }
    }
    .list{ position: relative; }
    .desc{ position: absolute; left: 0; top: 50%; right: 0; @include transform(translateY(-50%)); }
    h1{ font-size:61px; font-weight: bold; color: #fff; line-height: 80px; padding-bottom: 20px; border-bottom: 1px solid #fff;  }
    p{ color: #fff; font-size: 20px; line-height: 26px; margin:40px 0 35px;  }
}

.line-body{
    width: 1px; height: 1000px; position: absolute; left: 0; bottom: 0; top: 0; background: #DEDEDE; z-index: 9;
    &:before{
        content:''; position: absolute; width: 5px; height: 170px; @include borderRadius(10px);
        background: #fff; left: -2px; top: 0;
    }
    &.blue{
        &:before{ background:#009BE0; height: 80px; }
    }
}
.btn{
    &.rounded-arr{
        font-size: 16px; line-height: 50px; padding: 0 60px 0 35px; color: #fff; border:1px solid #fff;
         @include borderRadius(35px); overflow: hidden;
        @include boxSizing(border-box);display: inline-block; position: relative; text-transform: uppercase;
        @include transition(.4s all ease-out);
        &:after{
            content:''; position: absolute; right: 25px; top: 0; bottom: 0; margin: auto 0; width: 22px; height: 7px;
            background: url(../images/material/btn-arr.png)no-repeat center;
            @include transition(.2s all ease-out);
        }
        &:before{
            content: ''; z-index: -1;position: absolute; top: 0; bottom: 0; right: 0;  width: 0px; height: auto;
        border-radius: 35px; background: #193D6F;  transition: width 0.5s, opacity 0.3s;
        }
        &:hover{
            border-color: #193D6F;
            &:before{ width:100%; }
            &:after{ right: 15px; }
            &.gr{
                color: #fff;
                &:before{ background-color:#66B82E; }
                &:after{ background: url(../images/material/btn-arr.png)no-repeat center; background-size: 100%; }
            }
            &.green{
                 color: #fff;
                &:before{ background-color:#66B82E; }
                &:after{ background: url(../images/material/btn-arr.png)no-repeat center; }
            }
        }
        &.gr{
            border-color: #66B82E; color: #66B82E;
            &:after{ background:url(../images/material/btn-arr-gr.png)no-repeat center;  }
            &:before{  background:#66B82E; }
        }
        &.green{
             border-color: #66B82E; color: #fff;  background: #66B82E;
            &:after{background: url(../images/material/btn-arr.png)no-repeat center;  }
            &:before{  background:#66B82E; }
        }
        &.back{
            padding: 0 35px 0 60px;
            &:after{
                right:auto; left: 25px; background: url(../images/material/arr-left-gr.png)no-repeat center;
                 @include transform(scaleX(-1));
            }
            &:hover{
                &:after{
                    @include transform(scaleX(-1));
                }
            }
        }
    }
    &.arr{
        font-size: 14px; font-weight: bold; color: #009BE0; text-transform: uppercase; padding-right: 60px;
        position: relative;
        &:after{
            content:''; position: absolute; right: 26px; top: 0; bottom: 0; margin: auto 0; width: 26px; height: 7px;
            background: url(../images/material/btn-arr-blue.png)no-repeat center; @include transition(.2s all ease-out);
        }
        &:hover{
            &:after{
                right:15px;
            }
        }
    }
    &.rounded{
        text-transform: uppercase; color: #fff; font-size: 20px; font-weight: bold; @include borderRadius(40px);
        padding: 0 85px;  @include boxSizing(border-box); line-height: 60px; display: inline-block; background: #0154A6;
        cursor: pointer; @include transition(.2s all ease-out);
        &:hover{
            background:#2864A0;
        }
    }
}
.two-box{
    @include afterclear;
    .left,.right{ width: 48%; }
    .left{ margin-right: 4%; }
}
.video-home{
    .thumb-video{
        position: relative; height: 410px;   overflow: hidden; @include borderRadius(10px);
        img{
            position: relative; z-index: 2; width: 100%; height: 100%; object-fit: cover;
            @include transition(.2s all ease-out);
        }
        .ic-play{
            position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; z-index: 3;
            width: 120px; height: 120px; @include transition(.2s all ease-out);
            small{ color: #fff; text-transform: uppercase; font-size: 13px; text-align: center;display: block; }
            img{ @include transform(scale(1)); }
         }
         &:hover{
            >img{ @include transform(scale(1.08)); }
            .ic-play{
                img{ @include transform(scale(1)); }
            }
         }
    }
    .wrap-yt{ position: absolute; left: 0; top: 0; right: 0; bottom: 0;width: 100%; z-index: 4; display: none; }
    iframe{ width: 100%; height: 100%; }
}

.line-body-sec{
    position: absolute; left: 0; top: -1000px; height: auto; width: 1px; background:#DEDEDE;
     display: block; z-index: 2; bottom: -1000px;
 }
 .linesec-medium{
    position: absolute; left: -2px; top: 0; width:5px; height: 170px; background: #009BE0; z-index: 10;
    @include borderRadius(10px);
    &.wht{
        background: #fff;
    }
}
.home{
    h2{
        font-size: 49px; font-weight: 300; line-height: 60px; padding-bottom: 10px;position: relative; margin-bottom: 20px;
        &:after{ content:''; position: absolute; bottom: 0; left: 0; width: 70px; height: 1px; background: #66B82E;  }
     }
    h4{ font-size: 39px; font-weight: 200; color: #111; line-height: 70px; }
    h5{ font-size:13px; font-weight: bold; color: #009BE0; margin-bottom: 25px; line-height: 17px; }
    &.about{
        padding: 145px 0 120px; position: relative; overflow: hidden;
        &:after{
            content:''; position: absolute; right: 0; bottom: 0; background: url(../images/material/rounded-bg.png)no-repeat center;
            width: 550px; height: 632px; z-index: -1;
         }
        h3{ font-family: $f-ja; font-size: 20px; line-height: 30px; color: #646464; margin-bottom: 20px; }
        .tittle-video{ font-style: italic; font-size: 14px; line-height: 20px; margin-top: 10px; display: block;  }
    }
    &.register{
        padding: 110px 0 130px; overflow: hidden;
        background: rgba(0,155,224,1);background: -moz-linear-gradient(left, rgba(0,155,224,1) 0%, rgba(0,155,224,1) 0%, rgba(112,191,235,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,155,224,1)), color-stop(0%, rgba(0,155,224,1)), color-stop(100%, rgba(112,191,235,1)));
        background: -webkit-linear-gradient(left, rgba(0,155,224,1) 0%, rgba(0,155,224,1) 0%, rgba(112,191,235,1) 100%);
        background: -o-linear-gradient(left, rgba(0,155,224,1) 0%, rgba(0,155,224,1) 0%, rgba(112,191,235,1) 100%);
        background: -ms-linear-gradient(left, rgba(0,155,224,1) 0%, rgba(0,155,224,1) 0%, rgba(112,191,235,1) 100%);
        background: linear-gradient(to right, rgba(0,155,224,1) 0%, rgba(0,155,224,1) 0%, rgba(112,191,235,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009be0', endColorstr='#70bfeb', GradientType=1 );
        h2{
            color: #fff;
            &:after{ background:#fff; }
        }
        .right{
            h3{ color: #fff; font-size: 16px; line-height: 30px;}
        }
        .download-for{
            display: block; font-weight: bold; font-size: 16px; color: #fff; padding:15px 40px 15px 0px;
            line-height: 20px;
            border-bottom: 1px solid #fff;  width: 70%; position: relative;
            @include transition(.2s all ease-out); @include boxSizing(border-box);
            &:after{
                content:''; position: absolute; right: 15px; top: 25%; background: url(../images/material/ic-download.png)no-repeat center;
                width: 24px; height: 24px; @include transition(.2s all ease-out);
            }
            &:hover{
               &:after{
                    top:30%;
               }
            }
         }
         .wrap-list-formulir{
            margin-top: 50px;
            @include afterclear;
            .list{
                width: 48%; margin-right: 4%; margin-bottom: 15px; background: #fff; float: left;
                padding: 20px 30px; @include boxSizing(border-box); @include borderRadius(10px);

                @include boxShadow(0 0 25px -12px #000);  overflow: hidden; position: relative;

                &:nth-child(2n){ margin-right: 0; }
                @include afterclear;
                &:hover{
                    .num{
                        background: #66B82E; color: #fff;
                    }
                }
            }
            .num{
               float: left; padding:10px; @include borderRadius(100%); border: 1px solid #66B82E;
               font-size: 14px; font-weight: bold; color: #66B82E; background: #fff; @include transition(.2s all ease-out);
               &:after{
                content:''; width: 1px;  background: #66B82E; position: absolute; top: 55px; left: 49px;
                bottom: 0;

                }
            }
            p{
                float: left; display: inline-block; font-size: 16px; font-weight: 600; color:#111;
                width: 60%; margin-bottom: 0; padding: 0 25px; line-height: 24px;  @include boxSizing(border-box);
            }
            a{ display: inline-block; float: right; margin-top: 10px;}
         }
    }
    &.news{
        padding: 70px 0 120px; overflow: hidden; position: relative;
        .news-wrap{
            margin: 20px -20px 0;
            .list{
                width: 33.333333%; float: left; padding: 0 20px; @include boxSizing(border-box);
                &:hover{
                    figure{
                        img{ @include transform(scale(1.1)); }
                    }

                }
            }
            figure{
                overflow: hidden; @include borderRadius(10px 10px 0 0);
                img{ @include borderRadius(10px 10px 0 0); @include transition(.2s all ease-out); }
            }
            .inner{
                padding: 30px 35px; @include boxSizing(border-box); border:1px solid #E8E8E8;
                @include borderRadius(0 0 10px 10px);
                p{
                    font-size: 16px; color: #111; line-height: 24px; font-weight: 600; padding-top: 15px;
                    margin-bottom: 0; height: 75px; overflow: hidden; display: -webkit-box; text-overflow: ellipsis;
                    -webkit-line-clamp: 3; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }
        }
        .wrap-news{
            .btn{ margin-top: 40px; }
        }
        .row-date{
            padding-bottom: 20px; border-bottom: 1px solid #EEEEEE;
            @include afterclear;
            h6{ font-size:13px; font-weight: bold; color: #888888; text-transform: uppercase;  }
            .right{
                h6{ color:#66B82E;  }
            }
        }
    }
}

.banner-middle{
    position: relative; overflow: hidden;
    figure{
        img{ width: 100%; }
    }
    &:before{
        content:''; position: absolute;left: 0; top: 0; right: 0; bottom: 0;
        background: url(../images/banner/shd-banner-midle.png)no-repeat center; width: 100%;
        background-size: cover;
    }
    &.page-search{
        figure{
            img{ height: 400px; }
        }
    }
    &.noshd{
        &:before{ display:none; }
    }
    .desc{
        position: absolute; left: 0; top: 55%; right: 0; @include transform(translateY(-50%));
        color: #fff;
    }
    h5{ font-size: 13px; font-weight: bold; margin-bottom: 15px; text-transform: uppercase; }
    h1{ font-size:61px; font-weight: bold; line-height: 70px; padding-bottom: 25px; border-bottom: 1px solid rgba(255,255,255,0.4);  }
    p{ font-size: 20px; font-family: $f-ja; line-height: 26px; width: 50%; margin-top: 25px; }
}

.middle{
    position: relative; overflow: hidden; padding: 85px 0; min-height: 300px;
    .linesec-medium{ left: -102px; height: 80px; }
    &.landing-detail{
        padding-top: 240px;
        .line-body-sec{ top: 0; }
    }
    &.search-result{
        padding: 45px 0 85px;
    }
}
.text-search-result{ font-size: 20px; line-height: 26px; font-family: $f-ja; }
.ovhiden{ overflow: hidden; position: relative; }

.two-box-middle{
    position: relative; margin-bottom: 20px; display: block; padding: 5px 0;
    .right{ width: 65%; }
    &.margtop{ margin-top: 60px; }
    .left{
        width: 31%; margin-right: 4%;  @include boxSizing(border-box);
        b{ font-size: 18px;color: #111; margin-bottom: 15px; display: block; }
        p{
            font-size: 18px; line-height: 32px; color: #464646;
            a{ color: #464646; }
        }

    }
    h2{ font-size: 31px; font-weight: 200; line-height: 45px; color: #009BE0;  margin-bottom: 20px;  }
    .date{ display: block; font-weight: bold; color:#0154A6; font-size: 61px; text-align: center; }
    .monthyear{
        text-align: center; display:  block;font-size: 22px; font-weight: bold; text-transform: uppercase;
        color: #009BE0; margin-top: 10px;
    }
    h1{ font-size: 49px;  line-height: 70px; font-weight: 200; color:#0154A6;  }
    &.table{
        display: table; width: 100%;
        .left,.right{ display: table-cell; vertical-align:middle; float: none; }
        .left{
            width: 30%;  vertical-align: top;
            figure{
                img{ @include borderRadius(10px); }
            }
        }
        .right{
            width: 100%; padding: 0 20px; @include boxSizing(border-box);
            h6{ font-size: 13px; font-weight: bold; margin-bottom: 30px; color: #888888; }
            h5{ font-size:20px; font-weight: 600; margin-bottom: 25px; color: #111; line-height: 26px;  }
            p{ font-size:14px; line-height: 25px; color: #464646;}
        }
    }
    &.detail{
        .left{ width: 15%; }
        .right{ width: 81%; }
    }
}
.end-sec{
    margin:40px -1000px 60px -100px; border: none; background: #DEDEDE; height: 1px;
}
.img-middle{
    margin-bottom: 40px;
    img{ width: 100%; }
}
.share-detail{
    border:1px solid #EEEEEE; display: block; text-align: center; margin: 0 auto; width: 100px;
    span{
        display: block; font-size: 16px;font-weight: 600; color: #888888; padding: 15px 10px; border-bottom: 1px solid #EEEEEE;
        text-align: center;
    }
    a{ display: block;  text-align: center; margin: 30px 0;}
}
.list-download{
    margin-bottom: 15px; background: #fafafa; padding: 25px 40px 25px 90px; @include boxSizing(border-box);
    @include borderRadius(10px); width: 100%; position: relative; overflow: hidden; cursor: pointer;
    display: block; @include transition(.2s all ease-out);
    &:hover{
       background:#66B82E;
       .text-download{
            &:after{
                background:url(../images/material/ic-download.png)no-repeat center;
            }
            span{ color: #fff; }
       }
    }
    figure{
        width: 70px; position: absolute; left: 0; top: 0; bottom: 0;
        background: #66B82E url(../images/material/ic-pdf.png)no-repeat center;
    }
    .text-download{
        &:after{
            content:''; position: absolute; right: 2%; top: 0; bottom: 0;
             background: url(../images/material/ic-download-gren.png)no-repeat center;
            width: 24px; height: 23px; margin: auto 0;
        }
        span{ color: #333333; font-size: 16px; line-height: 24px; }
    }
    &.green{
        background: #EDF6E0;
          &:hover{
             background:#66B82E;
          }
    }
}
.wrap-acc-middle{
    &.faq{
        p{ font-size:16px; line-height: 32px;  }
        .grid-acc{
            padding: 20px 40px 0 0; background: #fff;  border:none; padding-bottom: 40px; border-bottom: 1px solid #eee;
            @include borderRadius(0); @include transition(.2s all ease-out);
            &:hover{
                border-color:#193D6F;
            }
            &:after{
                background: url(../images/material/arr-dwn-blue.png)no-repeat center; width: 24px; height: 15px;
                top: 20px; margin: 0; right: 0;
            }
        }
        .content-acc{ padding: 40px 0 20px; border-bottom: 1px solid #EEEEEE;}
        .list{
            margin: 0 0 40px; border: none; display: block;
            &.act{
                border:none;
                .grid-acc{
                    border-color: #fff;
                }
            }
        }
    }
    .grid-acc{
        padding: 25px 50px 25px 30px; @include boxSizing(border-box); background:#FAFAFA; @include borderRadius(10px);
        position: relative; cursor: pointer; border:1px solid #fff;
        &:after{
         content:''; position: absolute; right: 4%; top: 0; bottom: 0; background: url(../images/material/arr-dwn-gren.png)no-repeat center;
         width: 14px; height: 9px; margin: auto 0; @include transition(.2s all ease-out);
        }
        &:hover{
            border-color:#ddd;
        }
    }
    .list{
        border:1px solid #fff; @include borderRadius(10px);  @include transition(.2s all ease-out);
        margin-bottom: 10px;
        &.act{
            border-color: #BABABA;
             .grid-acc{
                &:after{ @include transform(rotate(180deg)); }
            }
        }

    }
    .inner{ padding: 25px; @include boxSizing(border-box); display: table; }
    .img-acc{     display: table-cell; width: 265px;   }
    .text{     display: table-cell; vertical-align: middle; width: 65%; padding-left: 20px;    }
    h3{ font-size: 25px; font-weight: 200; color: #009BE0; line-height: 30px; margin-bottom: 15px;}
    h4{ font-size:16px; font-weight: 600; line-height: 24px;  }
    p{ font-size: 14px; color: #888888; line-height: 22px; }
    .wrap-l-r{
        display: table; width: 100%; @include afterclear;
        .left,.right{ float: none; display: table-cell; vertical-align: middle;}
        .left{ width: 300px; padding: 0; display: inline-block; }
    }
    .list-download{
        margin-bottom: 0; width: 100%; display: inline-block; padding: 15px 20px 15px 70px;
        figure{ width: 50px; }
    }
    .btn-underline{
        text-decoration: underline; font-size: 16px; color: #0154A6; font-weight: 600;
        display: inline-block; width: 100%; text-align: right;
    }
    .content-acc{ display: none; }
}

.paging{
    text-align: center; margin:60px auto 0; width: auto; display: block;
    @include afterclear;
    a,span{
        font-size: 16px; color: #888888; padding: 10px 15px; @include boxSizing(border-box); border:1px solid #EEEEEE;
        display: inline-block; margin: 0 -1.5px; @include transition(.2s all ease-out); text-align: center;
        &.arr{
            color: #fff; background: #70BFEB; border-color: #70BFEB;
            &.prev{ }
            &.next{  }
        }
        &.active{ font-weight: bold; color: #111; }
        &:hover{
            border-color: #193D6F;
            &.arr{
                background: #193D6F; border-color: #193D6F;
            }
        }
    }
}
.input-form{
    margin: 40px 0;
    .label-tittle{ font-size: 16px; font-weight: 600; color: #111; margin: 30px 0 15px; display: block;
        line-height:1.4;
        &.list-title{
            padding-left:20px;
            position:relative;
            &:before{
                content:attr(data-counter);
                position:absolute;
                top:0px;
                left:0px;
            }
        }
    }

    &.prosedur{
        textarea{height: 130px; padding: 20px;}
    }
    .row{ display: block;  @include afterclear; }
    .col{
        width: 100%; float: left; @include afterclear; margin-bottom: 15px;
        position: relative;
        &.textarea{
            label{ bottom: auto; line-height: 40px; top: 10px; }
        }
        &.half{
            width: 48%; margin-right: 4%;
            &:nth-child(2n){ margin-right: 0; }
            input[type='text']{ padding: 20px; }
        }
        &.number-telp{
            label{
                padding-right: 50px;
                &:after{
                    content:'+62'; position: absolute; left: 170px; top: 0; bottom: 0; font-size: 18px; color:#646464;
                    font-weight: 200;
                    line-height: 68px;
                }
            }
            input[type='number']{ padding-left: 230px; }
        }
        .field{ position: relative; overflow: hidden; }
    }
    label{
        position: absolute; left: 20px; top: 0; bottom: 0; line-height: 70px; font-size: 13px; font-weight: 600; color: #009BE0;
        text-transform: uppercase;
     }
    input[type='text'],
    input[type='email'],
    input[type=date],
    input[type='number'],
    select,
    .field .row-option{
        @include boxSizing(border-box); height:70px; @include borderRadius(10px); border:1px solid #E2E2E2;
        width: 100%;padding: 0 20px 0 200px; font-size: 18px; color: #0154A6;
        background-color:#FFF;
        &:focus{  border-color:#009BE0; @include boxShadow(0 0 25px -15px #009BE0); }
    }
    .field .row-option{
        align-items:center;

    }
    .field{
        &:after{
            content:"";
            position:absolute;
            top:0;
            bottom:0;
            right:30px;
            width:24px;
            height:24px;
            margin:auto;
        }
        &.select-option:after{
            background:#FFF url(../images/material/arrow-ico.svg) no-repeat center;
        }
        &.date-input:after{
            background:#FFF url(../images/material/date-ico.svg) no-repeat center;
        }
    }
    select.empty{
        color: #BABABA
    }
    textarea{
        width: 100%; @include boxSizing(border-box); height: 230px; @include borderRadius(10px);
        padding: 60px 20px 20px 20px; font-size: 18px; color: #0154A6;resize: none;
         &:focus{ border-color:#009BE0;  @include boxShadow(0 0 25px -15px #009BE0); }
    }
    input[type='file']{
        width: 100%; bottom: 0; right: 0; left: 0; width: 100%; z-index: 2; cursor: pointer;
        @include boxSizing(border-box);
    }
    input.date-picker{
        background:#FFF url(../images/material/date-ico.svg) no-repeat 95% center;
    }
     select{
        background:#FFF url(../images/material/arrow-ico.svg) no-repeat 95% center;
    }
    @include placeholder{font-size: 18px; color: #BABABA; font-weight: 100; }
    .option-form{
         float: left;  position: relative;
        input[type='radio']{
            position: absolute; left:0; top: 0; bottom: 0; right: 0; width: 100%;  cursor: pointer;
            list-style: none;  border-top-style: none;  border-right-style: none; border-bottom-style: none;
             border-left-style: none; appearance: none; -webkit-appearance: none; -moz-appearance: none;
             background: transparent; outline: none; padding: 0; -webkit-tap-highlight-color:transparent;
            &:checked{
                +span{
                    background:#009BE0; color:#fff;
                }
            }

        }
        span{
            width:210px; line-height: 45px; text-align: center; color: #111; border:1px solid #009BE0; background: #fff;
             @include borderRadius(10px); margin:0 15px 10px 0; display: inline-block; font-size: 16px;
        }
    }
    .upload_file{
        position: relative;
        label{ line-height: 70px; }
     }
    .fileinput{
        width: 100% !important; border:1px solid #EEEEEE; @include borderRadius(10px); height: 70px !important;
        position: relative;  background: url(../images/material/ic-lampiran.png)no-repeat 98% center;
        span{ position: absolute; left: 200px; top: 0; bottom: 0; line-height: 70px;font-size: 18px; font-weight: 200; color:#646464; }
    }
    .chk-box{
        display: block;
        label{
            position: relative; font-size:16px; color:#646464; line-height: 24px; font-weight: 400; text-transform: none;
            padding-left: 45px;  display: block; left: 0;cursor: pointer; margin-bottom: 15px;
        }
        input[type='checkbox']{
            position: absolute; left: 0; top: 0; bottom: 0; background: url(../images/material/ic-chckbox.png)no-repeat center;
            width: 23px; height: 23px; @include boxSizing(border-box);
            &:checked{ background:url(../images/material/ic-chckbox-chk.png)no-repeat center; }
         }
    }
    .import{ font-size: 16px; color: red; }
    .alert{
        padding: 5px 20px 0 20px; height: auto; line-height: 20px;width: 100%;  @include boxSizing(border-box);
        color: red;  font-size: 14px; font-weight: 500; opacity:1; visibility: visible;
        display: inline-block;
    }
    .alert-input{
        input[type='text'],
        input[type='number'],
        input[type='email'],
        input[type='password'],textarea,input[type='file']{ border-color: red;  }
    }

    .row-option{
        display:flex;
        padding-left:20px;
        &.no-padding{
            padding-left:0 !important;
        }
        label{
            position:relative;
            left:unset;
            line-height:1.2;
            padding-left:22px;
            line-height:16px;
            margin-right:70px;
            &:last-child{
                margin-right:0;
            }
        }
        input[type=radio]{
            width:14px;
            height:14px;
            padding:0;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            margin:auto;
            background:url(../images/material/radio-ico.svg) no-repeat right center;
            &:checked{
                background-position:left center;
            }
        }
    }
}
.list-laporan{
    @include afterclear; margin: 0 -15px;
    .list{
        width: 33.333333%; float: left; padding: 0 15px; @include boxSizing(border-box); margin-bottom: 40px;
       @include borderRadius(10px);
    }
    .inner{  border:1px solid #E8E8E8;  }
    figure{
        height: 330px; overflow: hidden;
        img{ width: 100%; @include borderRadius(10px 10px 0 0);  }
    }
    .text{ padding:35px 45px; @include boxSizing(border-box); }
    h4{ font-size:25px; line-height: 32px; font-weight: 200; color:#009BE0; margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid #ddd; }
    p{ font-size:14px; line-height: 20px; margin-bottom: 20px;  }
}
.download-grey{
    font-size: 16px; color: #009BE0; padding: 25px 40px 25px 25px; @include boxSizing(border-box); background: #D8F1FF url(../images/material/arr-dwnload-blue.png)no-repeat 96% center;
    @include borderRadius(10px); display: inline-block; width: 100%; border:1px solid #fff;
    @include transition(.2s all ease-out);
    &:hover{
        background-color:#fff; border-color: #009BE0;
    }
 }

 .page404{
    height: 100vh; position: relative;
    .center{
        position: absolute; left: 0; top: 50%; right: 0; margin: auto; text-align: center; @include transform(translateY(-50%));
         width: 600px;
    }
    h5{ font-size:20px; font-weight: bold; letter-spacing: 3px; text-transform: uppercase;  }
    p{ text-align: center; line-height:26px;   }
    .line-bot{
        bottom: 0; left: 0; right: 0; position: absolute; display: block; width: 1px; min-height: 190px;
        background: #DEDEDE; margin: auto;
        &:after{
            content:''; position: absolute; left: -2px; right: 0; top: 0; background: #009BE0; width: 5px; @include borderRadius(5px);
            height: 80px;
        }
     }
     .wrap-btn {
        a {
            margin: 0 20px 0 0;
        }
     }
}
.thankyou-page{
    height: 100vh; position: relative;
    .center{
        position: absolute; left: 0; top: 50%; right: 0; margin: auto; text-align: center;
        @include transform(translateY(-45%));
         width: 600px;
    }
    img {margin: 0 0 75px 0;}
    h5{
        font-size:40px; font-weight: bold; text-transform: uppercase;
        color: #009be0; margin: 0 0 28px 0;
    }
    p{
        text-align: center; line-height:18px; font-size: 13px; color: #888;
        margin: 0 82px 30px 82px;
    }
    .line-bot{
        bottom: 0; left: 0; right: 0; position: absolute; display: block; width: 1px; min-height: 190px;
        background: #DEDEDE; margin: auto;
        &:after{
            content:''; position: absolute; left: -2px; right: 0; top: 0; background: #009BE0; width: 5px; @include borderRadius(5px);
            height: 80px;
        }
     }
}
.underconst{
        height: 100vh; position: relative; overflow: hidden;
    &:after{
        content:''; position: absolute; right: 0; bottom: 0; background: url(../images/material/abs-bottom.png)no-repeat bottom right;
        width: 662px; height: 403px;
    }
    .wrapper{ width:1415px; padding: 0; }
    .text{
        padding-top:50px; @include afterclear;
        .wrap-text{  width: 59%; position: relative; float: left; margin-top: 150px;}
        figure{   width: 39%; float: right; }
    }
    .logo{ padding-top: 80px; }
    .linesec-medium{ left: -102px; top: -5px;  }
    .line-body-sec{ left: -100px; top: 0; }
    h5{
        font-size: 20px; letter-spacing: 3px; font-weight: bold; text-transform: uppercase; color: #333333;
        margin-bottom: 25px;
     }
     h1{ font-size: 71px; font-weight: bold; line-height: 78px; text-transform: uppercase; color: #009BE0; margin-bottom: 20px; }
     p{ font-size:20px; font-family: $f-ja; line-height: 26px; color: #646464;  }

     .tow-column{
        @include afterclear;
        .col{ float: left; width:300px; margin-right:85px;   }
        a{ display: block; font-size: 14px; color: #333; margin-bottom: 10px; }
        b{ margin-bottom: 10px; display: block; color: #333333; }
        p{ font-size:14px; line-height: 20px; color: #333;  }
     }
     .end-sec{ margin: 40px 0 40px -100px; }
}
.result{
    h4{ font-size: 20px; line-height: 35px; margin-bottom: 15px; display: block; color: #111; font-weight: 600; }
    p{ font-size: 14px; color: #464646; margin-bottom: 20px; line-height: 25px; }
}
.wrap-input-search{
    input[type='text']{ width: 100%;background: transparent; border: none; border-bottom: 1px solid #fff; height:80px;
    font-size: 61px; color: #fff; font-weight: 600; @include boxSizing(border-box); border-top: none;}
    @include placeholder{ color: #fff; font-style: italic; font-weight: 200; }
}
.dashed{ border: 1px dashed #BABABA; margin: 40px 0; }
.solid{ margin: 20px 0; }
.desc-upload{ font-size: 16px; font-style: italic; color:#888888; margin: 10px 0 15px; display: block; }

.tab-form {
    display:none;
    &.active{
        display:block;
    }
    &:after{
        content:"";
        clear:both;
        display:block;
    }
}
/* footer
----------------------------------------------------------------------------------------------*/
footer{
    position: relative;
    &:after{
        content:''; position: absolute; right: 0; bottom: 0; background: url(../images/material/logo-fot-abs.png)no-repeat center;
        width: 105px; height: 96px;
    }
    .top{ background:#193D6F; padding:60px 0; @include boxSizing(border-box); }
    .middle-footer{
        background:#193D6F;
        .wrapper{ 
             position: relative;
            &:after{
                content:'';
                border-top: 1px solid rgba(255,255,255,.3);
                position: absolute;
                top: 0px;
                left: 100px;
                right: 100px;
            }
        }
        .slider-footer{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 50px;
            text-align: center;
            margin: 0 50px;
            position: relative;
            .list{
                margin: 0 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 33.33%;
            }
            .slick-arrow{
                font-size: 0px;
                border: none;
                background: url('../images/material/arr-left.png')no-repeat center;
                width: 10px;
                cursor: pointer;
                height: 15px;
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                margin: auto 0px;
                z-index: 2;
                &.slick-next{
                    transform: rotate(180deg);
                    left: auto;
                    right: 0px;
                }
            }
        }
    }
    .sub{ 
        float: right; width: 60%;
        h5{ font-size:14px; font-weight: bold; margin-bottom: 10px;  }
        a{
            color: #fff; display: block; margin-bottom: 10px;  @include transition(.2s all ease-out);
            &:hover{ color:#4F9F18; }
        }
        .sub-footer{
            float: left; width:210px; color: #fff; margin-right: 20px;
            &:first-child{ width:300px; }
            &:last-child{
                margin-right: 0;
                a{ margin: 0 20px 0 0 ; display: inline-block; }
            }
            a{
                img{ @include transition(.2s all ease-out); }
                &:hover{
                    img{ @include transform(scale(1.1)); }
                }
            }
            p{ line-height: 24px; }
        }
    }
    .bot{
        background: #4F9F18; padding: 10px 0;
        p{ text-align: center; margin-bottom: 0; color: #fff;}
        a{ color: #fff; }
    }
    .logo{ float: left; }
}